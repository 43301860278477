// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCoMolctfZ55X70PSPUTzHYVJh7Y1Rj_Fc",
    authDomain: "foodie-4cef9.firebaseapp.com",
    projectId: "foodie-4cef9",
    storageBucket: "foodie-4cef9.appspot.com",
    messagingSenderId: "42003658614",
    appId: "1:42003658614:web:1113cb378e1dddc4876c57",
    measurementId: "G-25LXDZPGSZ"
  },
  onesignal: {
    appId: '43b40899-6642-40df-b2ed-a25e38b05eb4',
    googleProjectNumber: '42003658614',
    restKey: 'OTQxMzAzNWMtMTlmNC00NDA4LWFlNzktZmMzZjA1NjE5MjNk'
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: '₹',
    code: 'Rs'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
